import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";

const SearchWidgetDemo = () => {
	return (
		<LayoutWidget>
			<Seo
				seo={{
					metaTitle: "Search Widget",
					metaDescription:
						"The new rentware search widget will show a list of all items directly on your website",
				}}
			/>
			<div className="hidden text-center lg:block">
				<StaticImage
					src="../../../images/tesla.jpg"
					alt="Tesla red"
					layout="constrained"
					width={1440}
					quality="80"
				/>
			</div>

			<div className="-mt-8 min-h-screen text-center md:px-4 lg:-mt-12">
				<rtr-search />
			</div>
		</LayoutWidget>
	);
};

export default SearchWidgetDemo;
